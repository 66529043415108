
#page-topbar-new {
    position: relative;
    height: 43px !important;
    width: 100%;
    left:0%;
    background-color: #F1F5F7 !important;
  }
  .navbar-header-new {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    padding: 10px;
  }
  .breadactive{
    color: #2800FC;
  }
  .breadcrumb-active:hover,.breadcrumb-active:focus{
    text-decoration: underline;
    cursor: pointer;
  }
  .profile-name{
    font-family:Roboto;
    font-weight: 400;
    font-size: 12px;
    color: #0C0C0C;
    line-height: 14.08px;
    font-style: normal;
  }
  .profile-chervon{
    width: 10.26px;
    height: 5.13px;
    padding-top: 0px;
    color:#2800FC ;
    font-size: 20px;
  }
  .span-font-roboto{
    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
}