.quickStylesBar {
  margin: 24px;
  // .QuickFiltersButtons{
  Button {
    height: 26px;
    line-height: 0px;
    font-size: 12px;
    margin: 8px;
  }

  // }
  .MuiPickerStaticWrapper-root {
    // .PrivatePickersSlideTransition-root{
    .MuiTypography-caption {
      font-size: 13px;
      font-family: Roboto;
      font-weight: 900;
      font-style: normal;
    }
    Button {
      height: 10pxpx;
      line-height: 5px;
      font-size: 12px;
      margin: 0px;
      font-family: Roboto;
      font-weight: 400;
      font-style: normal;
    }
    // }
  }

  .delayed {
    background-color: #ffe3e1;
    color: #e53d2c !important;
  }
  .untracked {
    background-color: #ffaba2;
    color: #ac1000 !important;
  }
  .reset {
    background-color: #0ab38c;
    color: #ffffff !important;
  }
}
.iconButtons {
  background-color: #f1f5f7;
  border-color: #614bcf !important;
}

//status text
.actionText {
  font-weight: 700;
  font-size: 13px;
}

//action dropdown
.dropbtn {
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.action-button {
  cursor: pointer;
  color: #2800fc !important;
}

.action-button-disabled {
  pointer-events: none !important;
  opacity: 0.7;
  color: #2800fc !important;
}

.action-button-cancel {
  cursor: pointer;
  color: #e53d2c !important;
}

//comment dropdown
.comment {
  position: relative;
  display: inline-block;
}

.comment-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.comment-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.comment-content div:hover {
  background-color: #ddd;
}

.comment:hover .comment-content {
  display: block;
}

// .action-button {
//   cursor: pointer;
//   color: black !important;
// }

//calender styles
.quickStylesBar {
  .MuiPickerStaticWrapper-root {
    position: fixed;
    z-index: 9999;
    direction: ltr !important;
  }
}
.consent-react_selector {
  min-width: 19%;
}
.consentBackground {
  background-color: #f1f5f7;
  padding: 10px;
  border-radius: 6px;
  .consentText {
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto";
    white-space: nowrap;
  }
  .unTrackedValueDiv {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    cursor: default;
  }
}
.QualityCheckModel {
  .modal-content {
    height: fit-content;
    padding: 20px;
    //  overflow-y: scroll;
  }
}
.QualityCheckCard {
  height: 400px;
  max-height: 420px;
  overflow-y: scroll;
  width: 95%;
  margin-left: 20px;
  background-color: #f1f5f7;
}
.QCImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 28px;
  height: 20px;
  border-radius: 11px;
  margin-top: 3px;
  margin-right: 28px;
  .closeIconQc {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.EpodAck {
  .modal-content {
    // min-width: 900px;
    padding: 25px;
  }
}
.DamagedShortageDetails {
  .modal-content {
    padding: 25px;
  }
}
.EPODImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 47px;
  height: 20px;
  border-radius: 11px;
  margin-top: 7px;
  margin-right: 28px;
  .closeIconEPOD {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.inputBox {
  width: 100%;
  height: 30px;
  border: 1px solid #d4c5c5;
}
@media (max-width: 640px) {
  .mobilePaddingBox {
    padding-top: 120px;
  }
  .pdt10 {
    padding-top: 10px;
  }
}
